import React, { useState, useContext, useEffect, useRef } from "react";
import { RemoteMongoClient } from "mongodb-stitch-browser-sdk";
import { StitchContext } from "../contexts/stitch-context";
import Button from "../components/button";
import TextInput from "../components/text-input";
import Upper from "../components/upper";

const Index = () => {
  const value = useContext(StitchContext);
  const [db, setDb] = useState(null);
  const [usersCollection, setUsersCollection] = useState(null);
  const [uniqueUrl, setUniqueUrl] = useState(null);
  const [createUrlInput, setCreateUrlInput] = useState("");
  const [createUrlStatus, setCreateUrlStatus] = useState({
    sending: false,
    success: false,
    error: false
  });
  const [tree, setTree] = useState(null);

  const createUrl = e => {
    console.log(createUrlInput);
    setCreateUrlStatus({ ...createUrlStatus, sending: true });
    usersCollection
      .updateOne(
        { _id: value.state.user.id },
        { $set: { unique_url: createUrlInput } }
      )
      .then(({ modifiedCount }) => {
        if (modifiedCount > 0) {
          setCreateUrlStatus({
            ...createUrlStatus,
            success: true,
            sending: false
          });
          setUniqueUrl(createUrlInput);
        }
      })
      .catch(e => {
        console.log(e.message);
        setCreateUrlStatus({
          ...createUrlStatus,
          error: true,
          sending: false
        });
      });
  };

  useEffect(
    () => {
      if (value.state) {
        if (!db && value.state.client) {
          setDb(
            value.state.client
              .getServiceClient(RemoteMongoClient.factory, "atlas")
              .db("todo")
          );
        }

        if (!usersCollection && db) {
          setUsersCollection(db.collection("users"));
        }

        if (value.state.isLoggedIn && value.state.user && usersCollection) {
          if (!tree) {
            console.log(value.state.user.auth.user);
            usersCollection
              .find({ _id: value.state.user.id })
              .asArray()
              .then(u => {
                if (u[0].unique_url) {
                  if (!uniqueUrl) {
                    console.log("setting unique url to: ", u[0].unique_url);
                    setUniqueUrl(u[0].unique_url);
                  }
                  setTree(
                    <div
                      style={{
                        marginTop: "120px",
                        fontSize: "30px",
                        fontWeight: 200
                      }}
                    >
                      <span role="img" aria-label="hand wave emoji">
                        👋🏽
                      </span>
                      <Upper>{value.state.user.profile.name}</Upper>
                      <strong>/{uniqueUrl}</strong>
                    </div>
                  );
                } else {
                  setTree(
                    <div>
                      <span role="img" aria-label="hand wave emoji">
                        👋🏽
                      </span>{" "}
                      {value.state.user.profile.name}
                      <form onSubmit={e => e.preventDefault()}>
                        <label>please create a unique URL</label>
                        <TextInput
                          style={{ display: "block" }}
                          id="unique-url"
                          name="unique-url"
                          type="text"
                          value={createUrlInput}
                          onChange={e => setCreateUrlInput(e.target.value)}
                        />
                        <Button onClick={createUrl} type="submit">
                          create
                        </Button>
                      </form>
                    </div>
                  );
                }
              })
              .catch(console.error);
          } else {
            // if the tree is not null - truthy
            setTree(
              <div>
                <span role="img" aria-label="hand wave emoji">
                  👋🏽
                </span>{" "}
                {value.state.user.profile.name}
                <hr />
                <form onSubmit={e => e.preventDefault()}>
                  <label>please create a unique URL</label>
                  <TextInput
                    style={{ display: "block" }}
                    id="unique-url"
                    name="unique-url"
                    type="text"
                    value={createUrlInput}
                    onChange={e => setCreateUrlInput(e.target.value)}
                  />
                  <Button onClick={createUrl} type="submit">
                    {createUrlStatus.sending ? "creating..." : "create"}
                  </Button>
                </form>
              </div>
            );
          }
        } else {
          // if user is not logged in, render empty tree
          setTree(null);
        }
      }
    },
    [value, db, usersCollection, createUrlInput, createUrlStatus, uniqueUrl]
  );

  return tree;
};

export default Index;
